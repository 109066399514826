import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Bannerpage = (props) => {
    const { homeJournalDataById } = useSelector((state) => state.Home);

    return (
        <>
            <div className="inner-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="instructor-wrap border-bottom-0 m-0">
                                <div className="about-instructor align-items-center"></div>
                            </div>
                            <h2>{homeJournalDataById?.data?.[0]?.journal_name}</h2>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: homeJournalDataById?.data?.[0]?.journal_homes?.[0]?.about_journal,
                                }}
                                className="truncate-two-lines"
                            ></p>

                            <div
                                className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                                style={{ justifyContent: "end" }}
                            >
                                <div className="cou-info">
                                    <h4 style={{ textAlign: "end" }}>
                                        {homeJournalDataById?.data?.[0]?.is_open_access ? "Open Access" : ""}
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                                style={{ justifyContent: "start" }}
                            >
                                <div className="cou-info">
                                    <div className="all-btn all-category">
                                        <Link
                                            to={props?.name ? `/${props?.name}/manuscript-form` : "/online-submission"}
                                            className="btn btn-primary"
                                        >
                                            Submit Manuscript
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <p>ISSN: {homeJournalDataById?.data?.[0]?.issn}</p>
                            <span className="web-badge mb-3">
                                <i className="fa-brands fa-whatsapp"></i> {homeJournalDataById?.data?.[0]?.whatsapp_number}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Add propTypes validation
Bannerpage.propTypes = {
    name: PropTypes.string, // Validate `name` as a string
};

export default Bannerpage;

import React from "react";
import PropTypes from "prop-types";

const PdfDownloader = ({ pdfUrl, fileName, children }) => {
  const handleDownload = () => {
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName; // Specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); // Clean up
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  return (
    <button 
    className="btn btn-primary"
    onClick={handleDownload}>
      {children || "Download PDF"}
    </button>
  );
};

// Define PropTypes
PdfDownloader.propTypes = {
  pdfUrl: PropTypes.string.isRequired, // PDF URL must be a string and is required
  fileName: PropTypes.string.isRequired, // File name must be a string and is required
  children: PropTypes.node, // Children can be any renderable node
};

// Define default props
PdfDownloader.defaultProps = {
  children: "Download PDF",
};

export default PdfDownloader;

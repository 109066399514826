import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Footer from "../footer";
import CourseHeader from "../header/index";
import StudentSideBar from "../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { SpissuesgetById } from "../../Redux/SpecialIssues/specialissueSlice";
import Bannerpage from "../Banner";
import ContentRenderer from "../ContentRender";

export default function Guidelines() {

  const { spIssue } = useSelector((state) => state.spIssueData)

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(SpissuesgetById(slug));
  }, []);

  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bannerpage name={slug} />

      <div className="container pt-5">
        <Row>
          <StudentSideBar />
          <Col style={{ backgroundColor: "#fff" }}>
            <div>
              <h1>Special Issue Guidelines</h1>
              {/* <div dangerouslySetInnerHTML={{ __html: spIssue?.data?.guidelines?.content }}></div> */}
              <ContentRenderer content={spIssue?.data?.guidelines?.content} />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

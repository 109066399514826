import React, { useEffect } from "react";
import Header from "../../header";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CourseHeader from "../../header/index";

import StudentSideBar from "../sidebar";
import Footer from "../../footer";
import { InstructionsgetById } from "../../../Redux/AuthorInstructions/AuthorInstructionSlice";
import { useDispatch, useSelector } from "react-redux";
import Bannerpage from "../../Banner";
import ContentRenderer from "../../ContentRender";

export default function JournalMalpracticeStatement() {

  const { instructions } = useSelector((state) => state.instuctionData)

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(InstructionsgetById(slug));
  }, []);

  return (
    <>
      <>
        <Header />
        <CourseHeader activeMenu={"CourseDetails"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bannerpage name={slug} />

        <div className="container p-5">
          <Row>
            <StudentSideBar />
            <Col>
              <h1>Publication ethics & malpractice statement</h1>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      {/* <div dangerouslySetInnerHTML={{ __html: instructions?.data?.ethical_malpractices?.content }}></div> */}
                      <ContentRenderer content={instructions?.data?.ethical_malpractices?.content} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    </>
  );
}

// import Collapse from 'react-bootstrap/Collapse';
import React, { useEffect, useState } from "react";
// import { useState } from "react";
// import { User1 } from "../../../imagepath";
import { Link } from 'react-router-dom';
// import FeatherIcon from "feather-icons-react";
import StudentSideBar from "../../../student/sidebar";
import user7 from '../../../../assets/img/user/836.jpg'
import { Col, Row } from "react-bootstrap";
// import { communications } from "../../../home/slider/topics";
import { useSelector } from "react-redux";
import ContentRenderer from "../../../ContentRender";


const DetailsContent = (props) => {

  // const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  // const [open3] = useState(false);
  // const [open4] = useState(false);

  const [data, setData] = useState({});
  const { highLightIssues } = useSelector((state) => state.Home)
  // const [activeIndex, setActiveIndex] = useState('')

  useEffect(() => {
    setData(props)
  }, [props])

  return (
    <>
      <section className="page-content course-sec">
        <div className="container">
          <div className="row">
            <StudentSideBar />
            <div className="col-lg-8">
              {/* Overview */}
              <div className="card overview-sec">
                <div className="card-body">
                  <ul>
                    <li>Journal h-index : {data?.pageData?.journal_homes?.[0]?.h_index}</li>
                    <li>Journal cite score : {data?.pageData?.journal_homes?.[0]?.city_score}</li>
                    <li>Journal impact factor : {data?.pageData?.journal_homes?.[0]?.impact_factor}</li>
                    <li>Average acceptance to publication time : ({data?.pageData?.journal_homes?.[0]?.publication_time})</li>
                    <li>Average article processing time : ({data?.pageData?.journal_homes?.[0]?.processing_time})</li>
                  </ul>
                  <h5 className="subs-title">Editorial Board</h5>
                  <div className="row">
                    {data?.pageData?.journal_homes?.[0]?.editorial_boards?.length > 0 &&
                      data?.pageData?.journal_homes?.[0]?.editorial_boards?.map((item, index) => {
                        return <div className="col-lg-4 col-md-6 d-flex" key={index}>
                          <div className="instructor-box flex-fill">
                            <div className="instructor-img">
                              <img className="img-fluid" style={{ height: 200 }} crossOrigin="anonymous" alt="" src={item?.image_url || user7} />
                            </div>
                            <div className="instructor-content">
                              <h5>
                                {item?.name}
                              </h5>
                              <div style={{ textAlign: 'left' }} >
                                <ContentRenderer content={item?.about} />
                              </div>
                            </div>
                          </div>
                        </div>
                      })}
                  </div>
                  <h5 className="subs-title">Submit Manuscript</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="instructor-detail">
                        <ContentRenderer content={data?.pageData?.journal_homes?.[0]?.submit_manuscript} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="subs-title">About the Journal</h5>
                    </div>
                  </div>
                  <ContentRenderer content={data?.pageData?.journal_homes?.[0]?.about_journal} />
                  <h5 className="subs-title">h-index</h5>
                  <ContentRenderer content={data?.pageData?.journal_homes?.[0]?.h_index_description} />
                  <h5 className="subs-title">Journal Highlights</h5>
                  <Row className="cardb">
                    {data?.pageData?.journal_highlights?.length === 0 && <p> No Data Found</p>}
                    {data?.pageData?.journal_highlights?.map((item, index) => {
                      return <Col key={index} md={4} xl={4} sm={4} lg={4}>
                        <ul>
                          <Link to={`/journal-highlight/${window.btoa(item?.highlight_name)}/${window.btoa(item?.highlight_content)}`}>
                            <li style={{ cursor: 'pointer' }} >
                              <i className="fas fa-chevron-right" /> &nbsp; {item?.highlight_name}</li>
                          </Link>
                        </ul>
                      </Col>
                    })}
                  </Row>
                  <h5 className="subs-title">Current Issue Highlights</h5>
                  <div className="row">
                    {highLightIssues?.data.map((detail, index) => (
                      <div className="col-xl-4 col-lg-4 col-md-6 d-flex" key={index}>
                        <div className="course-box course-design d-flex ">
                          <div className="product">
                            <div className="product-content">
                              <h6 className="title">
                                <Link
                                  to={`/${detail?.journal_slug}/article/${detail?.slug}`}
                                >
                                  {detail.article_title}
                                </Link>
                              </h6>
                              <div className="title mb-2 truncate-2-lines"
                                style={{ fontSize: "12px" }}
                              >
                                <ContentRenderer content={detail.abstract} />
                              </div>
                              <div className="course-group d-flex">
                                <div className="course-group-img d-flex">
                                  <img
                                    src={detail?.image_url}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="course-name">
                                    <h6 style={{ color: 'black', fontSize: 10 }}>
                                      <Link
                                        to={`/${detail?.journal_slug}/author/${detail?.slug}/archive`}
                                        state={"article"}
                                      >
                                        {detail?.authors?.map((item, indexData) => {
                                          return <em key={indexData} >{item?.author_name}{indexData < detail?.authors?.length - 1 && ', '}</em>
                                        })}
                                      </Link>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <span className="col-4">
                                  <Link
                                    to={`/${detail?.journal_slug}/abstract/${detail?.slug}`}
                                    className="butto">
                                    Abstract
                                  </Link></span>&nbsp;&nbsp;
                                <span className="col-3">
                                  <Link
                                    to={`/${detail?.journal_slug}/article/${detail?.slug}`}
                                    className="butto">
                                    HTML
                                  </Link>
                                </span>
                                {
                                  detail?.pdf_url && <span className="col-3">
                                    <Link to={detail?.pdf_url} className="butto">
                                      PDF
                                    </Link>
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="card overview-sec">
                <div className="card-body">
                  <h5 className="subs-title">Editorial Board</h5>
                  <div className="row">
                    {data?.pageData?.journal_homes?.[0]?.editorial_boards?.length > 0 &&
                      data?.pageData?.journal_homes?.[0]?.editorial_boards?.map((item, index) => {
                        return <div className="col-lg-4 col-md-6 d-flex" key={index}>
                          <div className="instructor-box flex-fill">
                            <div className="instructor-img">
                              <img className="img-fluid" style={{ height: 200 }} crossOrigin="anonymous" alt="" src={item?.image_url || user7} />
                            </div>
                            <div className="instructor-content">
                              <h5>
                                {item?.name}
                              </h5>
                              <div style={{ textAlign: 'left' }} >
                                <ContentRenderer content={item?.about} />
                              </div>
                            </div>
                          </div>
                        </div>
                      })}


                  </div>
                </div>
              </div> */}
              {/* /Overview */}
              {/* <div className="card review-sec">
                <div className="card-body">
                  <h5 className="subs-title">Submit Manuscript</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="instructor-detail">
                        <ContentRenderer content={data?.pageData?.journal_homes?.[0]?.submit_manuscript} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Course Content */}
              {/* <div className="card content-sec">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="subs-title">About the Journal</h5>
                    </div>
                  </div>
                  <ContentRenderer content={data?.pageData?.journal_homes?.[0]?.about_journal} />
                </div>
              </div> */}
              {/* /Course Content */}
              {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">h-index</h5>
                  <ContentRenderer content={data?.pageData?.journal_homes?.[0]?.h_index_description} />
                </div>
              </div> */}
              {/* Journal Highlights */}
              {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">Journal Highlights</h5>
                  <Row className="cardb">
                    {data?.pageData?.journal_highlights?.length === 0 && <p> No Data Found</p>}
                    {data?.pageData?.journal_highlights?.map((item, index) => {
                      return <Col key={index} md={4} xl={4} sm={4} lg={4}>
                        <ul>
                          <Link to={`/journal-highlight/${window.btoa(item?.highlight_name)}/${window.btoa(item?.highlight_content)}`}>
                            <li style={{ cursor: 'pointer' }} >
                              <i className="fas fa-chevron-right" /> &nbsp; {item?.highlight_name}</li>
                          </Link>
                        </ul>
                      </Col>
                    })}
                  </Row>
                </div>
              </div> */}
              {/* Current Issue Highlights */}
              {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">Current Issue Highlights</h5>
                  <div className="row">
                    {highLightIssues?.data.map((detail, index) => (
                      <div className="col-xl-4 col-lg-4 col-md-6 d-flex" key={index}>
                        <div className="course-box course-design d-flex ">
                          <div className="product">
                            <div className="product-content">
                              <h6 className="title">
                                <Link
                                  to={`/${detail?.journal_slug}/article/${detail?.slug}`}
                                >
                                  {detail.article_title}
                                </Link>
                              </h6>
                              <div className="title mb-2 truncate-2-lines"
                                style={{ fontSize: "12px" }}
                              >
                                <ContentRenderer content={detail.abstract} />
                              </div>
                              <div className="course-group d-flex">
                                <div className="course-group-img d-flex">
                                  <img
                                    src={detail?.image_url}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="course-name">
                                    <h6 style={{ color: 'black', fontSize: 10 }}>
                                      <Link
                                        to={`/${detail?.journal_slug}/author/${detail?.slug}/archive`}
                                        state={"article"}
                                      >
                                        {detail?.authors?.map((item, indexData) => {
                                          return <em key={indexData} >{item?.author_name}{indexData < detail?.authors?.length - 1 && ', '}</em>
                                        })}
                                      </Link>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <span className="col-4">
                                  <Link
                                    to={`/${detail?.journal_slug}/abstract/${detail?.slug}`}
                                    className="butto">
                                    Abstract
                                  </Link></span>&nbsp;&nbsp;
                                <span className="col-3">
                                  <Link
                                    to={`/${detail?.journal_slug}/article/${detail?.slug}`}
                                    className="butto">
                                    HTML
                                  </Link>
                                </span>
                                {
                                  detail?.pdf_url && <span className="col-3">
                                    <Link to={detail?.pdf_url} className="butto">
                                      PDF
                                    </Link>
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
              {/* Relative journals */}
              {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">Relevant Topics</h5>
                  <Row className="cardb">
                    {data?.relavantTopics?.map((item, index) => {
                      return <> {data?.pageData?.journal_name !== item?.journal_name &&
                        <Col key={index} md={4} xl={4} sm={4} lg={4} >
                          <ul>
                            <li><i className="fas fa-chevron-right" /> &nbsp; {item?.journal_name}</li>
                          </ul>
                        </Col>
                      }
                      </>
                    })}


                  </Row>
                </div>
              </div> */}
              {/* Instructor */}
              {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">About the instructor</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="abt-instructor-img">
                        <Link to="/instructor-profile">
                          <img
                            src={User1}
                            alt="img"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="instructor-detail">
                        <h5>
                          <Link to="/instructor-profile">Nicole Brown</Link>
                        </h5>
                        <p>UX/UI Designer</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        4.5 Instructor Rating
                      </span>
                    </div>
                  </div>
                  <div className="course-info d-flex align-items-center">
                    <div className="cou-info">
                      <img src={Play} alt="" />
                      <p>5 Courses</p>
                    </div>
                    <div className="cou-info">
                      <img src={Icon1} alt="" />
                      <p>12+ Lesson</p>
                    </div>
                    <div className="cou-info">
                      <img src={Icon2} alt="" />
                      <p>9hr 30min</p>
                    </div>
                    <div className="cou-info">
                      <img src={People} alt="" />
                      <p>270,866 students enrolled</p>
                    </div>
                  </div>
                  <p>
                    UI/UX Designer, with 7+ Years Experience. Guarantee of High
                    Quality Work.
                  </p>
                  <p>
                    Skills: Web Design, UI Design, UX/UI Design, Mobile Design,
                    User Interface Design, Sketch, Photoshop, GUI, Html, Css,
                    Grid Systems, Typography, Minimal, Template, English,
                    Bootstrap, Responsive Web Design, Pixel Perfect, Graphic
                    Design, Corporate, Creative, Flat, Luxury and much more.
                  </p>
                  <p>Available for:</p>
                  <ul>
                    <li>1. Full Time Office Work</li>
                    <li>2. Remote Work</li>
                    <li>3. Freelance</li>
                    <li>4. Contract</li>
                    <li>5. Worldwide</li>
                  </ul>
                </div>
              </div> */}
              {/* /Instructor */}
              {/* Reviews */}
              {/* <div className="card review-sec">
                <div className="card-body">
                  <h5 className="subs-title">Reviews</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="abt-instructor-img">
                        <Link to="instructor-profile">
                          <img
                            src={User1}
                            alt="img"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="instructor-detail">
                        <h5>
                          <Link to="/instructor-profile">Nicole Brown</Link>
                        </h5>
                        <p>UX/UI Designer</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        4.5 Instructor Rating
                      </span>
                    </div>
                  </div>
                  <p className="rev-info">
                    “ This is the second Photoshop course I have completed with
                    Cristian. Worth every penny and recommend it highly. To get
                    the most out of this course, its best to to take the
                    Beginner to Advanced course first. The sound and video
                    quality is of a good standard. Thank you Cristian. “
                  </p>
                  <Link to="#" className=" btn-reply">
                    <FeatherIcon icon="corner-up-left" />
                    Reply
                  </Link>
                </div>
              </div> */}
              {/* /Reviews */}
              {/* Comment */}
              {/* <div className="card comment-sec">
                <div className="card-body">
                  <h5 className="subs-title">Post A comment</h5>
                  <form action='#'>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        rows={4}
                        className="form-control"
                        placeholder="Your Comments"
                        defaultValue={""}
                      />
                    </div>
                    <div className="submit-section">
                      <button className=" submit-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div> */}
              {/* /Comment */}
            </div>
            {/* <div className="col-lg-3">
              <div className="sidebar-sec">
                <div className="card include-sec">
                  <div className="card-body">
                    <div className="cat-title">
                      <h4>Includes</h4>
                    </div>
                    <ul>
                      <li>
                        <img
                          src={Import}
                          className="me-2"
                          alt=""
                        />{" "}
                        11 hours on-demand video
                      </li>
                      <li>
                        <img
                          src={Play}
                          className="me-2"
                          alt=""
                        />{" "}
                        69 downloadable resources
                      </li>
                      <li>
                        <img
                          src={Key}
                          className="me-2"
                          alt=""
                        />{" "}
                        Full lifetime access
                      </li>
                      <li>
                        <img
                          src={Mobile}
                          className="me-2"
                          alt=""
                        />{" "}
                        Access on mobile and TV
                      </li>
                      <li>
                        <img
                          src={Cloud}
                          className="me-2"
                          alt=""
                        />{" "}
                        Assignments
                      </li>
                      <li>
                        <img
                          src={Teacher}
                          className="me-2"
                          alt=""
                        />{" "}
                        Certificate of Completion
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="card feature-sec">
                   
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsContent;

import React, { useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import { useParams } from 'react-router-dom'
import { 
    // JournalList, 
    JournalListPageDynamic } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";
import ContentRenderer from '../ContentRender';

const Editorialpolicies = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        // dispatch(JournalList(window.atob(name)));
        dispatch(JournalListPageDynamic(name));

    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }

    return (
        <>
            <div>
                <Header />
                <div className="breadcrumb-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        {/* <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol> */}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='topspace'>
                    <div className='container '>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Overview</h5>
                                {/* <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div> */}
                                <ContentRenderer content={journalList?.data?.page_content} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Editorialpolicies
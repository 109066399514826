import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization

const ContentRenderer = ({ content }) => {
    const decodeHtmlEntities = (htmlString) => {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = htmlString;
        return textarea.value;
    };

    const processContent = (htmlString) => {
        // Decode any escaped HTML entities
        const decodedContent = decodeHtmlEntities(htmlString);

        // Parse and modify the content
        const parser = new DOMParser();
        const doc = parser.parseFromString(decodedContent, 'text/html');

        // Add crossorigin to images
        const images = doc.querySelectorAll('img');
        images.forEach((img) => {
            img.setAttribute('crossorigin', 'anonymous');
        });

        // Secure anchor tags
        const anchors = doc.querySelectorAll('a');
        anchors.forEach((anchor) => {
            anchor.setAttribute('target', '_blank');
            anchor.setAttribute('rel', 'noopener noreferrer');
        });

        // Remove unwanted tags
        const unwantedTags = doc.querySelectorAll('head, script, style');
        unwantedTags.forEach((tag) => tag.remove());

        return doc.body.innerHTML;
    };

    // Sanitize the content for security and clean rendering
    const sanitizedContent = DOMPurify.sanitize(content ? processContent(content) : '');

    return (
        <div
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            className="content-renderer"
        />
    );
};

// PropTypes validation
ContentRenderer.propTypes = {
    content: PropTypes.string, // Validate that content is a string
};

// Default props
ContentRenderer.defaultProps = {
    content: '',
};

export default ContentRenderer;

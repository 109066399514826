import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { logo } from "../imagepath";
import './header.css'

// Dynamic Header
const Header = () => {
  const { settings } = useSelector((state) => state.Home);

  // const [navbar, setNavbar] = useState(false);

  // console.log(navbar, "dsfsdf")
  const [menuData, setMeanuData] = useState([]);

  const getMenuData = () => {
    axios.get(`${process.env.REACT_APP_URL}/pagemenu/journal`)
      .then((res) => {
        console.log(res?.data, "ddddddddddddddd")
        setMeanuData(res?.data?.data)
      })
      .catch((err) => {
        console.log(err, "ddddddddddddddd")
      })
  }

  useEffect(() => {
    getMenuData();
    // const changeHeaderBackground = () => {
    //   if (window.scrollY >= 50) {
    //     setNavbar(true);
    //   } else {
    //     setNavbar(false);
    //   }
    // };

    // window.addEventListener("scroll", changeHeaderBackground);
    // return () => window.removeEventListener("scroll", changeHeaderBackground);
  }, []);

  const Changeroute = (slugDD, name) => {
    let routePath = ''
    if (slugDD === "a-z-journals") {
      routePath = `/filter/${window.btoa(name)}`
    }
    else if (slugDD === "browse-by-subject") {
      routePath = `/subject-filter/${window.btoa(name)}`
    }
    else {
      routePath = `/pageData/${slugDD}`
    }
    return routePath;
  }

  const renderSubMenu = (subMenu) => {
    if (!subMenu || subMenu.length === 0) return null;

    return (
      <ul
        style={{
          display: "none", // Hidden by default
          position: "absolute",
          top: "100%",
          left: "0",
          background: "#fff",
          listStyle: "none",
          margin: "0",
          padding: "0",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
          zIndex: 1000,
        }}
        className="submenu"
      >
        {subMenu.map((item) => (
          <li
            key={item._id}
            style={{ position: "relative" }}
            className={item.sub_menu?.length > 0 ? "has-submenu" : ""}
            onMouseEnter={(e) => {
              const submenu = e.currentTarget.querySelector(".submenu");
              if (submenu) submenu.style.display = "block";
            }}
            onMouseLeave={(e) => {
              const submenu = e.currentTarget.querySelector(".submenu");
              if (submenu) submenu.style.display = "none";
            }}
          >
            <Link
              to={Changeroute(item.slug, item.menu)}
              style={{
                textDecoration: "none",
                padding: "10px 15px",
                display: "block",
                color: "#333",
              }}
            >
              {item.menu} {item.sub_menu?.length > 0 && <i className="fas fa-chevron-down"></i>}
            </Link>
            {renderSubMenu(item.sub_menu)}
          </li>
        ))}
      </ul>
    );
  };

  const renderMenu = () => {
    return menuData.map((menu) => (
      <li
        key={menu._id}
        style={{
          position: "relative", // To position dropdown under this item
          marginRight: "20px",
        }}
        className={menu.sub_menu?.length > 0 ? "has-submenu" : ""}
        onMouseEnter={(e) => {
          const submenu = e.currentTarget.querySelector(".submenu");
          if (submenu) submenu.style.display = "block";
        }}
        onMouseLeave={(e) => {
          const submenu = e.currentTarget.querySelector(".submenu");
          if (submenu) submenu.style.display = "none";
        }}
      >

        <Link
          to={menu.sub_menu?.length <= 0 ? Changeroute(menu.slug, menu.menu) : ''}
          style={{
            textDecoration: "none",
            padding: "10px 15px",
            display: "block",
            color: "#333",
          }}
        >
          {menu.menu} {menu.sub_menu?.length > 0 && <i className="fas fa-chevron-down"></i>}
        </Link>
        {renderSubMenu(menu.sub_menu)}
      </li>
    ));
  };



  return (
    <header className="header">
      <div className="header-fixed">
        <nav
          className={`navbar navbar-expand-lg header-nav add-header-bg`}
        >
          <div className="container">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand logo">
                <img
                  src={settings?.data?.color_logo_url}
                  crossOrigin="anonymous"
                  style={{
                    height: "70px", // Restricts the height
                    width: "auto", // Maintains aspect ratio
                    objectFit: "contain", // Ensures proper scaling without cropping
                    display: "block", // Prevents extra spacing
                    margin: "0 auto", // Centers the logo horizontally if needed
                  }}
                  alt="Logo"
                />

              </Link>
            </div>
            <div className="main-menu-wrapper">
              <ul className="main-nav">{renderMenu()}</ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;

// Static Header
// const Header = () => {

//   const { settings } = useSelector((state) => state.Home);

//   const getMenuData = () => {
//     axios.get(`${process.env.REACT_APP_URL}/pagemenu/journal`)
//     .then((res) => {
//       console.log(res?.data, "ddddddddddddddd")
//     })
//     .catch((err) => {
//       console.log(err, "ddddddddddddddd")
//     })
//   }

//   useEffect(() => {
//     getMenuData();
//     document.body?.classList?.remove("menu-opened");
//     return () => {
//       document.body.className = "";
//     };
//   }, []);

//   // change header background on scroll
//   const [navbar, setNavbar] = useState(false);
//   // Mobile Menu toggle
//   const [mobileSubMenu, setMobileSubMenu] = useState(false);
//   const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
//   const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
//   // const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
//   // const [mobileSubMenu5, setMobileSubMenu5] = useState(false);

//   const openMobileMenu = () => {
//     document.body?.classList?.add("menu-opened");
//   };
//   const hideMobileMenu = () => {
//     document.body?.classList?.remove("menu-opened");
//   };

//   const openMobileSubMenu = (e) => {
//     e.preventDefault();
//     setMobileSubMenu(!mobileSubMenu);
//   };
//   const openMobileSubMenu2 = (e) => {
//     e.preventDefault();
//     setMobileSubMenu2(!mobileSubMenu2);
//   };
//   const openMobileSubMenu3 = (e) => {
//     e.preventDefault();
//     setMobileSubMenu3(!mobileSubMenu3);
//   };
//   // const openMobileSubMenu4 = (e) => {
//   //   e.preventDefault();
//   //   setMobileSubMenu4(!mobileSubMenu4);
//   // };
//   // const openMobileSubMenu5 = (e) => {
//   //   e.preventDefault();
//   //   setMobileSubMenu5(!mobileSubMenu5);
//   // };

//   const changeHeaderBackground = () => {
//     if (window.location.pathname.startsWith("//Journal-")) {
//       if (window.scrollY >= 250) {
//         setNavbar(true);
//       } else {
//         setNavbar(false);
//       }
//     }
//     else {
//       if (window.scrollY >= 50) {
//         setNavbar(true);
//       } else {
//         setNavbar(false);
//       }
//     }

//   };

//   window.addEventListener("scroll", changeHeaderBackground);
//   return (
//     <header className="header" style={{ backgroundColor: "#38b6ff" }}>
//       <div className="header-fixed" style={{ backgroundColor: "#38b6ff" }}>
//         <nav
//           className={
//             navbar
//               ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
//               : "navbar navbar-expand-lg header-nav scroll-sticky"
//           } style={{ backgroundColor: "#fff" }}
//         >
//           <div className="container">
//             <div className="navbar-header " >
//               <Link id="mobile_btn" to="/" onClick={openMobileMenu}>
//                 <span className="bar-icon">
//                   <span />
//                   <span />
//                   <span />
//                 </span>
//               </Link>
//               <Link to="/" className="navbar-brand logo">
//                 <img src={settings?.data?.color_logo_url} crossOrigin="anonymous" style={{ marginTop: '-5%', height: '69px', width: 'auto' }} className="img-fluid" alt="Logo" />
//               </Link>
//             </div>
//             <div className="main-menu-wrapper">
//               <div className="menu-header">
//                 <Link to="/" className="menu-logo">
//                   <img src={settings?.data?.color_logo_url} crossOrigin="anonymous" style={{ height: '100px', width: '100px' }} className="img-fluid" alt="Logo" />
//                 </Link>
//                 <Link
//                   id="menu_close"
//                   className="menu-close"
//                   to="/"
//                   onClick={hideMobileMenu}
//                 >
//                   <i className="fas fa-times" />
//                 </Link>
//               </div>
//               <ul className="main-nav">
//                 <li className="has-submenu active">
//                   {mobileSubMenu ?
//                     <Link
//                       className={mobileSubMenu ? "submenu" : ""}
//                       to="/"
//                       onClick={openMobileSubMenu}
//                     >
//                       Home
//                     </Link>
//                     :
//                     <Link to="/" >
//                       Home
//                     </Link>
//                   }

//                 </li>
//                 <li className="has-submenu">
//                   <Link to="/" onClick={openMobileSubMenu2}>
//                     Journal <i className="fas fa-chevron-down" />
//                   </Link>
//                   <ul
//                     className={
//                       mobileSubMenu2 ? "submenu submenuShow" : "submenu"
//                     }
//                   >
//                     <li>
//                       <Link to={`/filter/${window.btoa('A-Z Journals')}`}>A-Z Journals</Link>
//                     </li>
//                     <li>
//                       <Link to={`/subject-filter/${window.btoa('Briwse By Subject')}`}>Browse By Subject</Link>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="has-submenu">
//                   <Link to="/students-list" onClick={openMobileSubMenu3}>
//                     Guidelines & Policies  <i className="fas fa-chevron-down"></i>
//                   </Link>
//                   <ul
//                     className={
//                       mobileSubMenu3
//                         ? "submenu first-submenu submenuShow"
//                         : "submenu first-submenu"
//                     }
//                   >
//                     <li>
//                       <Link to={`/editorial-policies/${window.btoa('Editorial Policies')}`}>Editorial Policies</Link>
//                     </li>
//                     <li>
//                       <Link to={`/online-submission/${window.btoa('Online Submission')}`}>Online Submission</Link>
//                     </li>
//                     <li>
//                       <Link to={`/author-instructions/${window.btoa('Instructions to Authors')}`}>
//                         Instructions to Authors
//                       </Link>
//                     </li>
//                     <li>
//                       <Link to={`/policies/${window.btoa('Policies')}`}>
//                         Policies
//                       </Link>
//                     </li>
//                     <li>
//                       <Link to={`/publication-ethics/${window.btoa('Publication ethics')}`}>Publication ethics</Link>
//                     </li>
//                     <li>
//                       <Link to={`/reviewers/${window.btoa('Reviewers')}`}>Reviewers</Link>
//                     </li>
//                     <li>
//                       <Link to={`/terms-and-conditions/${window.btoa('Terms and Conditions')}`}>Terms and Conditions</Link>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="has-submenu">
//                   <Link to="/covid-special" >
//                     Covid-19 Journal Article Issues
//                   </Link>
//                 </li>
//                 <li className="has-submenu">
//                   <Link to={`/advertising/${window.btoa('Advertising')}`} >
//                     Advertising
//                   </Link>
//                 </li>
//                 <li className="has-submenu">
//                   <Link to={`/confrences/${window.btoa('Conference')}`} >
//                     Confrences
//                   </Link>
//                 </li>
//                 <li className="has-submenu">
//                   <Link to="/contact-us" >
//                     Contact Us
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//         {/* } */}

//       </div>
//     </header>
//   );
// };

// export default Header;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLogin: false,
    manuscript: {},
    toastData: {},
    dropdownClassifications: {},
    dropdownArticleType: {},
    formSubmit: {},
    dropdownJournal: {},
};

const homeSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setManuscriptById(state, action) {
            state.manuscript = action.payload;
        },
        setDropdownClassifications(state, action) {
            state.dropdownClassifications = action.payload;
        },
        setformSubmit(state, action) {
            state.formSubmit = action.payload;
        },
        setDropdownArticleType(state, action) {
            state.dropdownArticleType = action.payload;
        },
        setDropdownJournalType(state, action) {
            state.dropdownJournal = action.payload;
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { settoastData, setManuscriptById, setDropdownClassifications, setDropdownArticleType, setDropdownJournalType, setformSubmit } = homeSlice.actions;

export const ManuscriptsgetById = (id) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/submitManuScript/${id}`).then((res) => {
        dispatch(setManuscriptById(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const dropdownClassificationGet = () => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journalClassificationDropdownList`).then((res) => {
        dispatch(setDropdownClassifications(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const dropdownArticleTypeGet = () => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/articleType`).then((res) => {
        dispatch(setDropdownArticleType(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const dropdownJournalGet = () => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/dropdownList`).then((res) => {
        dispatch(setDropdownJournalType(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}


export const formSubmitManuscript = (payload) => async (dispatch) => {
    dispatch(setformSubmit(''));
    const response = await axios.post(`${process.env.REACT_APP_URL}/submitArticleManuScript`, payload).then((res) => {
        dispatch(settoastData({ status: true, message: String(res?.data) }));
        dispatch(setformSubmit({ status: true }));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
            dispatch(setformSubmit({ status: false, message: err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data }));
        })
    return response;
}

export const changeStatus = () => async (dispatch) => {
    dispatch(setformSubmit(''));
}
const manuscriptReducer = homeSlice.reducer;

export default manuscriptReducer;
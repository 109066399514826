import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function PaymentCreate() {

    const { settings } = useSelector((state) => state.Home);

    const validationSchema = Yup.object({
        currency_code: Yup.string()
            .test(
                "currency-validation",
                "Please select a currency type if 'Other' is selected",
                function (value) {
                    const { currency_code } = this.parent; // Access the currency value
                    console.log(currency_code, value, !(currency_code === "Other" && value === "Other"), "valuevaluevalue")
                    return !((currency_code === "Other" && value === "Other") || currency_code === '')
                }
            )
            .required("Currency is required"),
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phone: Yup.string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
            .required("Phone is required"),
        country: Yup.string().required("Country is required"),
        journal_name: Yup.string().required("Journal Name is required"),
        article_title: Yup.string().required("Title of the article is required"),
        invoice_number: Yup.string().required("Invoice Number is required"),
        amount: Yup.number()
            .required("Chosen Price is required")
            .positive("Amount must be positive"),
    });

    const formik = useFormik({
        initialValues: {
            "currency_code": "",
            "name_title": "",
            "name": "",
            "email": "",
            "phone": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": "",
            "journal_name": "",
            "article_title": "",
            "invoice_number": "",
            "address": "",
            "amount": ""
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            // console.log("Form Submitted", values);
            axios.post(`${process.env.REACT_APP_URL}/payment/create`, values)
                .then(() => {
                    toast.success("Payment Created !")
                    resetForm();
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data || "Something Went Wrong")
                })
        },
    });


    return (
        <div className="container p-5">
            <div className="paymentform-container">
                <div >
                <img
                  src={settings?.data?.color_logo_url}
                  crossOrigin="anonymous"
                  style={{
                    height: "auto", // Restricts the height
                    width: "90px", // Maintains aspect ratio
                    objectFit: "contain", // Ensures proper scaling without cropping
                    display: "block", // Prevents extra spacing
                    margin: "0 auto", // Centers the logo horizontally if needed
                  }}
                  alt="Logo"
                />
                    <hr />
                </div>
                <form className="payment-form" onSubmit={formik.handleSubmit}>
                    <fieldset>
                        <p>Note: * Fields are mandatory</p>
                        <div className="row">
                            <div className="form-group">
                                <p>
                                    Choose Currency *:&nbsp;
                                    <input
                                        name="currency_code"
                                        type="radio"
                                        value="USD"
                                        onChange={formik.handleChange}
                                    />{" "}
                                    USD ($) &nbsp;
                                    <input
                                        name="currency_code"
                                        type="radio"
                                        value="Euro"
                                        onChange={formik.handleChange}
                                    />{" "}
                                    Euro (€) &nbsp;
                                    <input
                                        name="currency_code"
                                        type="radio"
                                        value="Other"
                                        onChange={formik.handleChange}
                                    />{" "}
                                    Other &nbsp;
                                    <select
                                        name="currency_code"
                                        disabled={formik.values.currency_code !== "Other"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option value="">--Select Currency Type--</option>
                                        <option value="INR">Indian</option>
                                        <option value="CUP">Cuban Peso</option>
                                    </select>
                                </p>
                                <div className="text-danger">
                                    {formik.touched.currency_code && formik.errors.currency_code}
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        name="name_title"
                                        value={formik.values.name_title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Name *</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.name && formik.errors.name}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>E-mail *</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.email && formik.errors.email}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Phone *</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.phone && formik.errors.phone}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.city && formik.errors.city}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.state && formik.errors.state}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Zip</label>
                                    <input
                                        type="text"
                                        name="zip"
                                        value={formik.values.zip}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.zip && formik.errors.zip}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Country *</label>
                                    <input
                                        type="text"
                                        name="country"
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.country && formik.errors.country}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Journal Name *</label>
                                    <input
                                        type="text"
                                        name="journal_name"
                                        value={formik.values.journal_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.journal_name && formik.errors.journal_name}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Title of the article *</label>
                                    <input
                                        type="text"
                                        name="article_title"
                                        value={formik.values.article_title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.article_title && formik.errors.article_title}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Invoice Number *</label>
                                    <input
                                        type="text"
                                        name="invoice_number"
                                        value={formik.values.invoice_number}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.invoice_number && formik.errors.invoice_number}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Address</label>
                                    <textarea
                                        name="address"
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.address && formik.errors.address}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Chosen Price *</label>
                                    <input
                                        type="text"
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="text-danger">
                                        {formik.touched.amount && formik.errors.amount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div className="col-lg-4 col-md-4 justify-content-center align-items-center">
                        <button className="btn btn-primary" type="submit">
                            Proceed to Pay
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

import React, { useEffect } from "react";
import CourseHeader from "../../header/index";
import DetailsContent from "../../pages/course/courseDetails/detailsContent";
import Footer from "../../footer";
import { Link, useLocation, useParams } from "react-router-dom";
import { getCurrentHighlights, HomeJournalDatagetById } from "../../../Redux/home/homeslice";
import { useDispatch, useSelector } from "react-redux";
import Bannerpage from "../../Banner";

const Journalpage = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(HomeJournalDatagetById(slug));
    dispatch(getCurrentHighlights(slug));
  }, [])

  const { homeJournalDataById } = useSelector((state) => state.Home)
  
  return (
    <>
      <div className="main-wrapper">
        <CourseHeader activeMenu={"CourseDetails"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bannerpage name={slug}/>
        <DetailsContent pageData={homeJournalDataById?.data?.[0]} relavantTopics={location?.state?.journals} />
        <Footer />

      </div>
    </>
  );
};

export default Journalpage;
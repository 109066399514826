import React, { useEffect, useState } from "react";
// import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import InnerBanner from "../../assets/img/inner-banner.jpg";
import Footer from "../footer";
import Header from "../header";
import CourseHeader from "../header/index";

import { useDispatch, useSelector } from "react-redux";
import { dropdownClassificationGet, dropdownArticleTypeGet, formSubmitManuscript, changeStatus, dropdownJournalGet } from "../../Redux/submitmanuscript/submitmanuscriptSlice";
import Bannerpage from "../Banner";
import axios from "axios";
import { HomeJournalDatagetById } from "../../Redux/home/homeslice";
import { Col, Row } from "react-bootstrap";


export default function SubmitManuscript() {

    const { slug } = useParams();

    const [fileArray, setFileArray] = useState([''])
    console.log(fileArray, "asacsgacsa")
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        alternate_email: "",
        mobile: "",
        country: "",
        title: "",
        article_type_uuid: "",
        issue_type: "",
        special_issue_title: "",
        classification_uuid: "",
        journal_uuid: "",
        suggest_classification: "",
        abstract: "",
        keywords: "",
        attach_file: fileArray
    });

    const [errors, setErrors] = useState({});

    const addFiles = () => {
        setFileArray([...fileArray, ''])
    }

    const removeFile = (index) => {
        if (index === 0) {
            setFileArray(['']);
        }
        else {
            setFileArray(fileArray.filter((_, i) => i !== index));
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [uploadLoaders, setUploadLoaders] = useState(Array(fileArray.length).fill(false));

    const handleFileChange = (e, index) => {
        const file = e.target.files[0]; // Assume single file upload; adjust for multiple if needed
        const newformData = new FormData();
        newformData.append('file', file);

        // Set the loader state for the current index to true
        const updatedLoaders = [...uploadLoaders];
        updatedLoaders[index] = true;
        setUploadLoaders(updatedLoaders);

        axios.post(`${process.env.REACT_APP_URL}/upload/imageFile`, newformData)
            .then((res) => {
                const updatedFiles = [...fileArray];
                updatedFiles[index] = res?.data?.data?.image?.filename;
                setFileArray(updatedFiles);

                const name = "attach_file";
                setFormData({ ...formData, [name]: updatedFiles });

                // Set the loader state for the current index to false
                updatedLoaders[index] = false;
                setUploadLoaders(updatedLoaders);
            })
            .catch((err) => {
                console.log(err, "Error uploading file");
                const updatedFiles = [...fileArray];
                updatedFiles[index] = "";
                setFileArray(updatedFiles);

                // Set the loader state for the current index to false
                updatedLoaders[index] = false;
                setUploadLoaders(updatedLoaders);
            });
    };

    // const handleFileChange = (e, index) => {
    //     const file = e.target.files[0];  // Assume single file upload; adjust for multiple if needed
    //     const newformData = new FormData();
    //     newformData.append('file', file);

    //     axios.post(`${process.env.REACT_APP_URL}/upload/imageFile`, newformData)
    //         .then((res) => {

    //             const updatedFiles = [...fileArray];
    //             updatedFiles[index] = res?.data?.data?.image?.filename;
    //             setFileArray(updatedFiles);
    //             const name = "attach_file"
    //             setFormData({ ...formData, [name]: updatedFiles });
    //         })
    //         .catch((err) => {
    //             console.log(err, "Error uploading file");
    //             const updatedFiles = [...fileArray];
    //             updatedFiles[index] = "";
    //             setFileArray(updatedFiles);
    //         });
    // };

    const [fileArrayError, setFileArrayError] = useState(false);

    const validateForm = () => {
        const requiredFields = ["first_name", "last_name", "email", "mobile", "country", "title", "article_type_uuid", "issue_type", "classification_uuid", "abstract"];
        const newErrors = {};
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
            }
        });
        const hasEmptyFile = fileArray.some(file => !file);
        if (hasEmptyFile) {
            setFileArrayError(true);
        } else {
            setFileArrayError(false);
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0 && !hasEmptyFile;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            dispatch(formSubmitManuscript(formData));
        } else {
            console.log("Validation errors", errors);
        }
    };

    const { dropdownClassifications, dropdownArticleType, dropdownJournal, formSubmit } = useSelector((state) => state.manuscriptData)

    const [countryList, setCountryList] = useState([]);

    const dispatch = useDispatch();

    const getAllCountryList = () => {
        axios.get('https://restcountries.com/v3.1/all')
            .then((res) => {
                setCountryList(res?.data)
            })
            .catch((err) => {
                console.log(err, "ddddddddddddddddddddddddddddddddd")
            })
    }

    useEffect(() => {
        const data = dropdownJournal?.data?.filter((data) => data?.journal_slug === slug);
        formData.journal_uuid = data?.[0]?.uuid
    }, [dropdownJournal])

    useEffect(() => {
        if (formSubmit?.status) {
            alert("Form Submitted !");
            dispatch(changeStatus());
            const data = dropdownJournal?.data?.filter((data) => data?.journal_slug === slug);
            setFormData({
                first_name: "",
                last_name: "",
                email: "",
                alternate_email: "",
                mobile: "",
                country: "",
                title: "",
                journal_uuid: data?.[0]?.uuid,
                article_type_uuid: "",
                issue_type: "",
                special_issue_title: "",
                classification_uuid: "",
                suggest_classification: "",
                abstract: "",
                keywords: "",
                attach_file: []
            });
            setFileArray(['']);
        }
        else if (formSubmit?.status === false) {
            alert(String(formSubmit?.message))
            dispatch(changeStatus());
        }
    }, [formSubmit])

    useEffect(() => {
        dispatch(dropdownClassificationGet());
        dispatch(dropdownArticleTypeGet());
        getAllCountryList();
        dispatch(HomeJournalDatagetById(slug));
        dispatch(dropdownJournalGet())
    }, [])

    return (
        <>
            <>
                <Header />
                <CourseHeader activeMenu={"CourseDetails"} />
                <div className="breadcrumb-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                All Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                The Complete Web Developer Course 2.0
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Bannerpage name={slug} />

                <div className="container p-5">
                    <h1>Submit Manuscript</h1>

                    <div className="form-container">
                        <form className="manuscript-form"
                        >
                            <fieldset>
                                <h5>Author Details:</h5>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Author First Name *</label>
                                            <input
                                                type="text"
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.first_name && formData.first_name === '' && '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group">
                                            <label>Author Last Name *</label>
                                            <input
                                                type="text"
                                                name="last_name"
                                                value={formData.last_name}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.last_name && '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Author Email *</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.email && '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Alternate Email</label>
                                            <input
                                                type="email"
                                                name="alternate_email"
                                                value={formData.alternate_email}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Phone Number *</label>
                                            <input
                                                type="tel"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.mobile && '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Choose your Region *</label>
                                            <select
                                                name="country"
                                                value={formData.country}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.country && '2px solid red'
                                                }}
                                            >
                                                <option value="">--Choose your Region--</option>
                                                {countryList?.map((item, index) => {
                                                    return <option value={item?.name?.common} key={index}>{item?.name?.common}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <h5>Manuscript Details:</h5>
                                {/* <div className="row">
                                <div className="col">
                                        <div className="form-group">
                                            <label>Article Type *</label>
                                            <select
                                                name="article_type_uuid"
                                                value={formData.article_type_uuid}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.article_type_uuid && '2px solid red'
                                                }}
                                            >
                                                <option value="">--Select Article Type--</option>
                                                {dropdownJournal?.data?.map((item, index) => {
                                                    return <option value={item?.uuid} key={index}>{item?.journal_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Title *</label>
                                            <input
                                                type="text"
                                                name="title"
                                                value={formData.title}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.title && '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Article Type *</label>
                                            <select
                                                name="article_type_uuid"
                                                value={formData.article_type_uuid}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.article_type_uuid && '2px solid red'
                                                }}
                                            >
                                                <option value="">--Select Article Type--</option>
                                                {dropdownArticleType?.data?.map((item, index) => {
                                                    return <option value={item?.uuid} key={index}>{item?.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Issue Type *</label>
                                            <select
                                                name="issue_type"
                                                value={formData.issue_type}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.issue_type && '2px solid red'
                                                }}
                                            >
                                                <option value="">--Select Issue Type--</option>
                                                <option value="special_issue">Special Issue</option>
                                                <option value="regular_issue">Regular</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Special Issue Title</label>
                                            <input
                                                type="text"
                                                name="special_issue_title"
                                                value={formData.special_issue_title}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Journal Name *</label>
                                            <select
                                                name="journal_uuid"
                                                value={formData.journal_uuid}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.article_type_uuid && '2px solid red'
                                                }}
                                            >
                                                <option value="">--Select Journal--</option>
                                                {dropdownJournal?.data?.map((item, index) => {
                                                    return <option value={item?.uuid} key={index}>{item?.journal_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Classifications *</label>
                                            <select
                                                name="classification_uuid"
                                                value={formData.classification_uuid}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.classification_uuid && '2px solid red'
                                                }}
                                            >
                                                <option value="">--Select Classification--</option>
                                                {dropdownClassifications?.data?.map((item, index) => {
                                                    return <option value={item?.uuid} key={index}>{item?.name}</option>
                                                })}
                                                <option value="not_found">Not Found</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {formData.classification_uuid === "not_found" &&
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Suggest Classification</label>
                                                <input
                                                    type="text"
                                                    name="suggest_classification"
                                                    value={formData.suggest_classification}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Keywords</label>
                                            <textarea
                                                type="text"
                                                name="keywords"
                                                value={formData.keywords}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Abstract *</label>
                                            <textarea
                                                name="abstract"
                                                value={formData.abstract}
                                                onChange={handleInputChange}
                                                required
                                                style={{
                                                    border: errors.abstract && '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">

                                    <div className="row " >
                                        <div className="col-lg-4 col-md-4 col-sm-3 me-2">
                                            <Row style={{ paddingBottom: '10px' }}>
                                                <Col>
                                                    <label>Attach Your File *</label>
                                                </Col>
                                                <Col>
                                                    <div className="col-lg-4 col-md-4 col-sm-3 justify-content-center align-items-center">
                                                        <button className="btn btn-primary" type="button" onClick={addFiles}>Add More +</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {fileArray?.map((item, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
                                                    {item !== '' ? (
                                                        item
                                                    ) : (
                                                        <>

                                                            {uploadLoaders[index]
                                                                ?
                                                                <span style={{ marginLeft: '8px' }}>Uploading...</span>
                                                                :
                                                                <input
                                                                    type="file"
                                                                    required
                                                                    style={{
                                                                        border: fileArrayError && item === '' && '2px solid red',
                                                                    }}
                                                                    accept=".pdf, .doc"
                                                                    onChange={(event) => handleFileChange(event, index)}
                                                                />
                                                            }
                                                        </>
                                                    )}
                                                    {!uploadLoaders[index] && (fileArray?.length > 1 || item !== '') && (
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => removeFile(index)}
                                                            style={{ marginLeft: '8px' }}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            {/* {fileArray?.map((item, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center', paddingBottom : '10px' }}>
                                                    {item !== '' ? item :
                                                        <input
                                                            type="file"
                                                            required
                                                            style={{
                                                                border: fileArrayError && item === '' && '2px solid red'
                                                            }}
                                                            accept=".pdf, .doc"
                                                            onChange={(event) => handleFileChange(event, index)}
                                                        />
                                                    }
                                                    {(fileArray?.length > 1 || item !== '') &&
                                                        <button className="btn btn-primary" onClick={() => removeFile(index)} style={{ marginLeft: '8px' }}>
                                                            Remove
                                                        </button>
                                                    }
                                                </div>
                                            ))} */}
                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                            <div className="col-lg-4 col-md-4 justify-content-center align-items-center">
                                <button className="btn btn-primary" type="button" onClick={handleSubmit}>Submit Manuscript</button>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </>
        </>

    );
}

import React, { useEffect, useState } from "react";
import CourseHeader from "../header/index";
import { Col, Row } from "react-bootstrap";
import {
    Link,
    // useLocation,
    useParams
} from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
// import InnerBanner from "../../assets/img/inner-banner.jpg";
import StudentSideBar from "../student/sidebar";

// import {
//     useDispatch
//     // , useSelector
// } from "react-redux";
// import { SpissuesgetById } from "../../Redux/SpecialIssues/specialissueSlice";
import Bannerpage from "../Banner";
// import queryString from 'query-string';
// import moment from "moment";
import { Html, Pdf } from "../imagepath";
import axios from "axios";

const AuthorInfoJournal = () => {

    // const { spIssue } = useSelector((state) => state.spIssueData)
    // const location = useLocation();
    // const query = queryString.parse(location.search);

    // Parse the article data back to an object
    // const article = query.article ? JSON.parse(query.article) : null;

    const [article, setArticle] = useState({})

    // const dispatch = useDispatch();
    const { ArticleHtml, type } = useParams();

    const getArchiveDetailesBySlug = () => {
        axios.get(`${process.env.REACT_APP_URL}/articleDetails/${ArticleHtml}`)
            .then((res) => {
                console.log(res?.data)
                setArticle(res?.data?.data?.[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    console.log(article, "Parsed article");


    useEffect(() => {
        // dispatch(SpissuesgetById(slug));
        getArchiveDetailesBySlug()
    }, [])

    // const { spIssue } = useSelector((state) => state.spIssueData)
    // const location = useLocation();
    // const query = queryString.parse(location.search);

    // // Parse the article data back to an object
    // const article = query.article ? JSON.parse(query.article) : null;

    // console.log(article, "Parsed article");

    // const dispatch = useDispatch();
    const { slug } = useParams();

    // useEffect(() => {
    //     dispatch(SpissuesgetById(slug));
    // }, [])

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bannerpage name={slug} />

            <div className="container p-5">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        {/* <div className="cardb" style={{ paddingTop: 10 }}>
                            <Row>
                                <Col>
                                    <label>{article?.article_types?.[0]?.name} - {volume}</label>
                                </Col>
                                <Col>
                                    <div style={{ textAlign: 'right' }}>
                                        <Link
                                            to={article?.pdf_url}
                                            target="_blank"
                                        >
                                            <button>
                                                View PDF
                                            </button>
                                        </Link> &nbsp; <PdfDownloader pdfUrl={article?.pdf_url} fileName="document.pdf">Download PDF</PdfDownloader>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                            <h1 className="text-dark"><u>{article?.authors?.[0]?.author_name}</u></h1>
                        </div>
                        <div>
                            <label>Department of {article?.authors?.[0]?.department}{article?.authors?.[0]?.institue_college && ','} {article?.authors?.[0]?.institue_college}{article?.authors?.[0]?.country && ","} {article?.authors?.[0]?.country}</label>
                        </div>
                        <div>
                            <h4 className="text-dark">Publications</h4>
                        </div>
                        <div className="card"
                            style={{
                                borderColor: 'lightgray',
                                backgroundColor: '#f8f9fa',
                                padding: 20,
                                borderRadius: 4
                            }}>
                            <strong style={{ fontSize: 15 }}>{article?.article_types?.[0]?.name} </strong>
                            <div>
                                <label>
                                    <Link
                                        to={type === "archive" ? `/${slug}/archive/Volume ${article?.volume_issue?.[0]?.volume}, Issue ${article?.volume_issue?.[0]?.issue}/${article?.slug}` : `/${slug}/article/${article?.slug}`}
                                        state={"article"}
                                    // target="_blank"
                                    >
                                        {article?.article_title}
                                    </Link>
                                </label>
                            </div>
                            <label>Department of {article?.authors?.[0]?.department}{article?.authors?.[0]?.institue_college && ','} {article?.authors?.[0]?.institue_college}{article?.authors?.[0]?.country && ","} {article?.authors?.[0]?.country}</label>
                            <div>
                                <b>Author(s): </b> <Link>
                                    <em style={{ textDecoration: 'underline', cursor: 'pointer' }}>{article?.authors?.[0]?.author_name}*</em>
                                </Link>
                            </div>
                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <p><strong>DOI: </strong> {article.doi}</p>
                            </div>
                            <div className="about-instructor">
                                <div >
                                    <Link
                                        to={type === "archive" ? `/${slug}/archive/Volume ${article?.volume_issue?.[0]?.volume}, Issue ${article?.volume_issue?.[0]?.issue}/${article?.slug}` : `/${slug}/article/${article?.slug}`}
                                        state={"article"}
                                    // target="_blank"
                                    >
                                        <img src={Html} alt="html" width="30" className="img-fluid"/>
                                    </Link>
                                </div>
                                <div >
                                    <Link
                                        to={article?.pdf_url}
                                    // target="_blank"
                                    >
                                        <img
                                            src={Pdf}
                                            alt="img"
                                            className="img-fluid"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </Col>

                </Row>
            </div>
            <Footer />
        </>
    )
}

export default AuthorInfoJournal
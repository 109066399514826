import React, { useEffect, useState } from "react";
import CourseHeader from "../header/index";
import { Col, Row } from "react-bootstrap";
import {
    Link,
    useParams
} from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
import StudentSideBar from "../student/sidebar";
import Bannerpage from "../Banner";
import moment from "moment";
import { Pdf, Html } from "../imagepath";
import axios from "axios";
import ContentRenderer from "../ContentRender";

const AbstractData = () => {

    const [article, setArticle] = useState({})
    const { ArticleHtml, slug } = useParams();

    const getArchiveDetailesBySlug = () => {
        axios.get(`${process.env.REACT_APP_URL}/articleDetails/${ArticleHtml}`)
            .then((res) => {
                console.log(res?.data)
                setArticle(res?.data?.data?.[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    console.log(article, "Parsed article");


    useEffect(() => {
        getArchiveDetailesBySlug()
    }, [])

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bannerpage />
            <div className="container p-5">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                            <h1><u>Abstract</u></h1>
                        </div>
                        <div>
                            <strong style={{ fontSize: 25 }}>{article?.article_title}</strong>
                        </div>
                        <div>
                            <label>
                                <Link
                                    to={`/${slug}/author/${article?.slug}/article`}
                                    state={"article"}
                                // target="_blank"
                                >
                                    {article?.authors?.map((item, indexData) => {
                                        return <em key={indexData} >{item?.author_name}{indexData < article?.authors?.length - 1 && ', '}</em>
                                    })}
                                </Link></label>
                        </div>
                        {/* {article?.fulltexts?.map((item, articleIndex) => {
                            return <div key={articleIndex}>
                                <h3>{item?.heading_details?.[0]?.heading}</h3>
                                <ContentRenderer content={item?.content || ''} />
                            </div>
                        })} */}
                        <ContentRenderer content={article?.abstract || ''} />

                        <div>
                            <strong>Published Date: </strong> {moment(article?.published_date)?.format('DD-MMM-YYYY')}; <strong>Recieved Date: </strong> {moment(article?.received_date)?.format('DD-MMM-YYYY')}
                        </div>
                        <div >
                            <Link
                                to={`/${slug}/article/${article?.slug}`}
                                state={"article"}
                            // target="_blank"
                            >
                                <img src={Html} alt="html" width="30" />
                            </Link>
                            <Link
                                to={article?.pdf_url}
                                target="_blank"
                            >
                                <img
                                    src={Pdf}
                                    alt="img"
                                    className="img-fluid"
                                />
                            </Link>
                        </div>
                        <Row>
                            <Col>
                                <div className="rating">
                                    <label>Share this article </label>
                                    <i className="fa-brands fa-facebook"></i>
                                    <i className="fa-brands fa-twitter"></i>
                                    <i className="fa-brands fa-linkedin"></i>
                                    <i className="fa-brands fa-instagram"></i>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}

export default AbstractData;
import React, { useEffect, useState } from "react";
import CourseHeader from "../header/index";
import { Col, Row } from "react-bootstrap";
import {
    Link,
    // useLocation,
    useParams
} from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
// import InnerBanner from "../../assets/img/inner-banner.jpg";
import StudentSideBar from "../student/sidebar";

// import {
//     useDispatch
//     // , useSelector
// } from "react-redux";
// import { SpissuesgetById } from "../../Redux/SpecialIssues/specialissueSlice";
import Bannerpage from "../Banner";
// import queryString from 'query-string';
import moment from "moment";
import PdfDownloader from "../DownloadPDF";
import axios from "axios";
import ContentRenderer from "../ContentRender";

const ArchiveHtml = () => {

    // const { spIssue } = useSelector((state) => state.spIssueData)
    // const location = useLocation();
    // const query = queryString.parse(location.search);

    // Parse the article data back to an object
    // const article = query.article ? JSON.parse(query.article) : null;

    const [article, setArticle] = useState({})

    // const dispatch = useDispatch();
    const { ArchiveHtml, volume, slug } = useParams();

    const getArchiveDetailesBySlug = () => {
        axios.get(`${process.env.REACT_APP_URL}/articleDetails/${ArchiveHtml}`)
            .then((res) => {
                console.log(res?.data)
                setArticle(res?.data?.data?.[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    console.log(article, "Parsed article");


    useEffect(() => {
        // dispatch(SpissuesgetById(slug));
        getArchiveDetailesBySlug()
    }, [])

    const mergedAffiliations = article?.authors
    ?.map(author => author?.author_anchor_affiliation || [])
    .reduce((acc, affiliations) => acc.concat(affiliations), []);

    let currentIndex = 1;

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bannerpage />

            <div className="container p-5">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        <div className="cardb" style={{ paddingTop: 10 }}>
                            <Row>
                                <Col>
                                    <label>{article?.article_types?.[0]?.name} - {volume}</label>
                                </Col>
                                <Col>
                                    <div style={{ textAlign: 'right' }}>
                                        <Link
                                            to={article?.pdf_url}
                                            target="_blank"
                                        >
                                            <button
                                                className="btn btn-primary"
                                            >
                                                View PDF
                                            </button>
                                        </Link> &nbsp; <PdfDownloader pdfUrl={article?.pdf_url} fileName="document.pdf">Download PDF</PdfDownloader>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <strong style={{ fontSize: 25 }}>{article?.article_title}</strong>
                        </div>
                        <div>
                            <Link
                                to={`/${slug}/author/${article?.slug}/archive`}
                                state={"article"}
                            // target="_blank"
                            >
                                <label>{article?.authors?.[0]?.author_name}</label>
                            </Link>
                        </div>
                        {article?.fulltexts?.map((item, articleIndex) => {
                            return <div key={articleIndex}>
                                <h3>{item?.heading_details?.[0]?.heading}</h3>
                                {/* <div dangerouslySetInnerHTML={{ __html: item?.content }}></div> */}
                                <ContentRenderer content={item?.content} />
                            </div>
                        })}
                        <div className="card"
                            style={{
                                borderColor: 'lightgray',
                                backgroundColor: '#f8f9fa',
                                padding: 20,
                                borderRadius: 4
                            }}>
                            <strong style={{ fontSize: 20 }}>Author Info</strong>
                            <div>
                                {/* <label>{article?.authors?.[0]?.author_name}</label> */}
                                {article?.authors?.map((item, indexData) => {
                                    const hasAffiliations = item?.author_anchor_affiliation?.length > 0;

                                    return (
                                        <label key={indexData}>
                                            {item?.author_name}
                                            {hasAffiliations && (
                                                <sup>
                                                    {item?.author_anchor_affiliation?.map((_, indd) => {
                                                        const index = currentIndex++; // Assign and increment the index
                                                        return (
                                                            <em key={indd}>
                                                                {index}
                                                                {indd < item?.author_anchor_affiliation.length - 1 && ','}
                                                            </em>
                                                        );
                                                    })}
                                                </sup>
                                            )}
                                            {indexData < article?.authors?.length - 1 && ', '}
                                        </label>
                                    );
                                })}
                                {/* {article?.authors?.map((item, indexData) => {
                                    return <label key={indexData} >
                                        {item?.author_name}
                                        <sup>
                                            {item?.author_anchor_affiliation.map((itemdd, indd) => {
                                                return <em key={indd}>{indd + 1}{indd < item?.author_anchor_affiliation?.length - 1 && ','}</em>
                                            })}
                                        </sup>
                                        {indexData < article?.authors?.length - 1 && ', '}
                                    </label>
                                })} */}
                            </div>
                            {mergedAffiliations?.map((affiliation, indexss) => (
                                <label key={indexss}><em>{indexss + 1} </em>Department of {affiliation?.department}{affiliation?.university_name_1 && ','} {affiliation?.university_name_1}{affiliation?.country && ','} {affiliation?.country}</label>
                            ))}
                            {/* <label>Department of {article?.authors?.[0]?.department}, {article?.authors?.[0]?.institue_college}, {article?.authors?.[0]?.country}</label> */}
                            <div style={{ marginTop: 20 }}>
                                <b>Citation:</b> {article?.article_citation}
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <b>Received:</b> {moment(article?.received_date).format('DD-MMM-YYYY')}, Manuscript No. {article?.manuscript_number}; <b>Editor assigned:</b> {moment(article?.editor_assigned)?.format('DD-MMM-YYYY')}, Pre QC No. {article?.pre_qc_number} (PQ); <b>Reviewed:</b> {moment(article?.reviewed_date)?.format('DD-MMM-YYYY')}, QC No. {article?.qc_number}; <b>Revised:</b> {moment(article?.revised_date)?.format('DD-MMM-YYYY')}, Manuscript No. {article?.manuscript_number} (R); <b>Published:</b> {moment(article?.published_date)?.format('DD-MMM-YYYY')} , DOI: {article?.doi}
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <b>Copyright:</b> {article?.article_copyright}

                            </div>
                        </div>

                    </Col>

                </Row>
            </div>
            <Footer />
        </>
    )
}

export default ArchiveHtml
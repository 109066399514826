import React, { useEffect } from "react";
import CourseHeader from "../../header/index";

import Footer from "../../footer";
import { Link, useParams } from "react-router-dom";
import user7 from '../../../assets/img/user/836.jpg'
import StudentSideBar from "../../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { EditorialsgetById } from "../../../Redux/Editorial/editorialSlice";
import ContentRenderer from "../../ContentRender";

const EditorinchiefIndividual = () => {

    const { editorial } = useSelector((state) => state.editorialData)
    const { slug, details, type } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(EditorialsgetById(slug));
    }, [])

    return (
        <>
            <div className="main-wrapper">
                <CourseHeader activeMenu={"CourseDetails"} />
                <div className="breadcrumb-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                All Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                The Complete Web Developer Course 2.0
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="inner-banner"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="instructor-wrap border-bottom-0 m-0">
                                    <div className="about-instructor align-items-center">
                                        <p>ISSN: 2165-7548</p>
                                    </div>
                                    <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
                                </div>
                                <h2>Journal Name</h2>
                                <p>
                                    Learn Web Development by building 25 websites and mobile apps
                                    using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                                </p>
                                <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                                >
                                    <div className="cou-info">
                                        <p style={{ textAlign: "end" }}>Open Access</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="page-content course-sec">
                    <div className="container">
                        <div className="row">
                            <StudentSideBar />
                            <div className="col-lg-8">
                                {type === "cheif" && editorial?.data?.[0]?.editor_in_chiefs?.filter(items => items.uuid === String(window.atob(details)))?.map((item, index) => {
                                    return <div className="card overview-sec" key={index}>
                                        <div className="card-body">
                                            <h5 className="subs-title">Editor-in-Chief</h5>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 d-flex">
                                                    <div className="instructor-box flex-fill">
                                                        <div className="instructor-img">
                                                            <Link>
                                                                <img className="img-fluid" alt="" src={item?.image_url || user7} crossOrigin="anonymous" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 d-flex">
                                                    <div className="instructor-content">
                                                        <h5>
                                                            <Link style={{ textAlign: "center" }}>{item?.name}</Link>
                                                        </h5>
                                                        {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.about }}></div> */}
                                                        <div style={{ textAlign: "left" }}>
                                                            <ContentRenderer content={item?.about} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Biography</Link>
                                                </h5>
                                                {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div> */}
                                                <div style={{ textAlign: "left" }}>
                                                    <ContentRenderer content={item?.biography} />
                                                </div>
                                            </div>
                                            <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Research Interest</Link>
                                                </h5>
                                                {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div> */}
                                                <div style={{ textAlign: "left" }}>
                                                    <ContentRenderer content={item?.research_interest} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                {(type === "board" || type === "boardb" || type === "boardr") && editorial?.data?.[0]?.editorial_boards?.filter(items => items.uuid === String(window.atob(details)))?.map((item, index) => {
                                    return <div className="card overview-sec" key={index}>
                                        <div className="card-body">
                                            <h5 className="subs-title">Editor-in-Chief</h5>
                                            {/* <h6>Course Description</h6> */}
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 d-flex">
                                                    <div className="instructor-box flex-fill">
                                                        <div className="instructor-img">
                                                            <Link>
                                                                <img className="img-fluid" alt="" src={item?.image_url || user7} crossOrigin="anonymous" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 d-flex">
                                                    <div className="instructor-content">
                                                        <h5>
                                                            <Link style={{ textAlign: "center" }}>{item?.name}</Link>
                                                        </h5>
                                                        {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.about }}></div> */}
                                                        <div style={{ textAlign: "left" }}>
                                                            <ContentRenderer content={item?.about} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {type === "board" && <>
                                                <div className="instructor-content">
                                                    <h5>
                                                        <Link style={{ textAlign: "center" }}>Biography</Link>
                                                    </h5>
                                                    {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div> */}
                                                    <div style={{ textAlign: "left" }}>
                                                        <ContentRenderer content={item?.biography} />
                                                    </div>
                                                </div>
                                                <div className="instructor-content">
                                                    <h5>
                                                        <Link style={{ textAlign: "center" }}>Research Interest</Link>
                                                    </h5>
                                                    {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div> */}
                                                    <div style={{ textAlign: "left" }}>
                                                        <ContentRenderer content={item?.research_interest} />
                                                    </div>
                                                </div>
                                            </>}
                                            {type === "boardb" && <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Biography</Link>
                                                </h5>
                                                {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div> */}
                                                <div style={{ textAlign: "left" }}>
                                                    <ContentRenderer content={item?.biography} />
                                                </div>
                                            </div>}
                                            {type === "boardr" && <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Research Interest</Link>
                                                </h5>
                                                {/* <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div> */}
                                                <div style={{ textAlign: "left" }}>
                                                    <ContentRenderer content={item?.research_interest} />
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        </>
    );
};

export default EditorinchiefIndividual;

import React, { useEffect } from "react";
import CourseHeader from "../header/index";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
// import InnerBanner from "../../assets/img/inner-banner.jpg";
import StudentSideBar from "../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { SpissuesgetById } from "../../Redux/SpecialIssues/specialissueSlice";
import Bannerpage from "../Banner";
import ContentRenderer from "../ContentRender";

const UpcomingIssues = () => {

    const { spIssue } = useSelector((state) => state.spIssueData)

    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(SpissuesgetById(slug));
    }, [])

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bannerpage name={slug} />

            <div className="container p-5">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        <div className="cardb">
                            <h1>Upcoming Special Issue</h1>
                            {/* <div dangerouslySetInnerHTML={{ __html: spIssue?.data?.upcoming_special_issues?.content }}></div> */}
                            <ContentRenderer content={spIssue?.data?.upcoming_special_issues?.content} />
                        </div>
                    </Col>

                </Row>
            </div>
            <Footer />
        </>
    )
}

export default UpcomingIssues
import React, { useEffect, useState } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useNavigate, useParams } from 'react-router-dom'
import { JournalList, JournalBrowsebyList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap';
import FeatherIcon from "feather-icons-react";
import ContentRenderer from '../ContentRender';

const SubjectFilter = () => {
    // const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList, browseBy } = useSelector((state) => state.jounalListData)
    const [search, setSearch] = useState('')
    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
        dispatch(JournalBrowsebyList(search));
    }, [name])

    // const SearchFun = (e) => {
    //     e.preventDefault()
    //     dispatch(JournalBrowsebyList(search));
    // }

    const navigation = useNavigate();

    const NavigateFun = (dataID, jounalsD) => {
        navigation(`/${dataID?.journal_slug}/journal-page`, {
            state: { journals: jounalsD } // 'state' is used to pass the data
        });
    }

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace container'>
                <div className='card'>
                    <div className='card-body'>
                        {/* <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div> */}
                        <ContentRenderer content={journalList?.data?.page_content} />
                    </div>
                </div>
                <Row>
                    <Col md={2} lg={2}>
                        {/* <h6 style={{ padding : '20px', backgroundColor : 'red', borderRadius : 8 ,}}>Journals by Title</h6> */}
                        <h6
                            style={{
                                padding: '10px',
                                backgroundColor: '#02CCFE',
                                borderRadius: '8px 0 0 8px',
                                color: 'white',
                                clipPath: 'polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%)'
                            }}
                        >
                            Journals by Subject
                        </h6>

                    </Col>
                    <Col md={10} lg={10}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                                gap: '10px', // Space between items
                            }}
                        >
                            {browseBy?.data?.subject?.map((item, index) => (
                                <Link
                                    key={index}
                                    to={`/${item?.slug}/more-Subject`}
                                    style={{ textDecoration: 'none' }} // Ensures links don't have default underline
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#02CCFE', // Primary button color
                                            color: '#fff', // Button text color
                                            padding: '10px',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            fontSize: '14px', // Uniform text size
                                            fontWeight: 'bold',
                                            height: '50px', // Ensures consistent button height
                                        }}
                                    >
                                        {item?.name}
                                    </div>
                                </Link>
                            ))}
                        </div>

                    </Col>
                </Row>
                <form
                    className="search-form"
                    style={{ padding: 10, width: '30%', marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}
                >
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                            onChange={(event) => { dispatch(JournalBrowsebyList(event.target.value)); setSearch(event.target.value) }}
                        />
                        {/* <button type="submit" className="btn btn-primary" onClick={(e) => SearchFun(e)}>
                            <i className="fa fa-search" />
                        </button> */}
                    </div>
                </form>

                {browseBy?.data?.result?.map((item, index) => {
                    return <>
                        <h3 key={index}>{item?.classification_name}</h3>
                        <div className='row pt-3'>
                            {item?.journals?.map((itemData, indexData) => {
                                return <div className='col-3' key={indexData} >
                                    <div className='card'>
                                        <div className='card-body'>
                                            {/* <h6>{itemData?.journal_name}</h6> */}
                                            <div className="about-instructor">
                                                <div className="abt-instructor-img">
                                                    <img
                                                        src={itemData?.image_url}
                                                        alt="img"
                                                        onClick={() => NavigateFun(itemData, itemData?.journals)}
                                                        crossOrigin='anonymous'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                                <div className="instructor-detail">
                                                    <h6 style={{ cursor: 'pointer' }} onClick={() => NavigateFun(itemData, itemData?.journals)}>{itemData?.journal_name}</h6>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='all-btn all-category'>

                                                        <Link
                                                            to={`/${itemData?.journal_slug}/journal-archive`}
                                                            className="btn btn-primary"
                                                            style={{ fontSize: 10 }}
                                                        >
                                                            <FeatherIcon icon="archive" /> Archive
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='all-btn all-category'>
                                                        <Link
                                                            to={`/${itemData?.journal_slug}/manuscript-form`}
                                                            className="btn btn-primary"
                                                            style={{ fontSize: 10 }}
                                                        >
                                                            <FeatherIcon icon="bookmark" /> Submit Paper
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='row'>
                                                <div className='col-9'>
                                                    <p>
                                                        ISSN
                                                    </p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>0.17</p>
                                                </div>
                                            </div> */}
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <p>
                                                        Journal impact factor</p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>{itemData?.impact_factor}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <p>
                                                        Journal h-index</p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>{itemData?.h_index}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <p>
                                                        Journal cite Score</p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>{itemData?.city_score}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p>
                                                        Average acceptance to publication time ({itemData?.publication_time})
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p>
                                                        Average article processing time ({itemData?.processing_time})
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </>
                })}
                {/* <div className='row pt-3'>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Acute and Chronic Disease Reports</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Advancements in Genetic Engineering</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Advancements in Genetic Engineering</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div >
            <Footer />
        </>
    )
}

export default SubjectFilter
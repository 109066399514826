import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";
import ContentRenderer from '../ContentRender';

const Reviewers = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string?.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <h1>Guide for reviewers</h1>
                    {/* <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div> */}
                    <ContentRenderer content={journalList?.data?.page_content} />
                    {/* <p>
                        Submitted manuscripts are usually reviewed by 2 (or more) experts. Peer reviewers will be
                        asked to recommend whether a manuscript should be accepted, revised or rejected. They
                        should also alert the editors of any issues relating to author misconduct, such as
                        plagiarism and unethical behavior.
                    </p>
                    <p>Longdom Publishing S.L.’s journals operate using a single-blind peer review system, in
                        which both authors and reviewers are anonymous.</p>
                    <h4>Points to Consider</h4>
                    <p>Reviewers are asked to provide detailed, constructive comments that will help the
                        editors make a decision regarding publication and how the authors could improve their
                        manuscript. A key issue is whether the work has serious methodological flaws that
                        should preclude its publication, or whether additional experiments or data are
                        required to support the conclusions. Where possible, reviewers should provide
                        references to substantiate their comments.</p> */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Reviewers